@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'segoe-pro';
    src: url(../../public/fonts/SegoePro-Regular.ttf);
  }
  @font-face {
    font-family: 'segoe-pro';
    font-style: italic;
    src: url(../../public/fonts/SegoePro-Italic.ttf);
  }
  @font-face {
    font-family: 'segoe-pro';
    font-weight: bold;
    src: url(../../public/fonts/SegoePro-Bold.ttf);
  }
  @font-face {
    font-family: 'segoe-pro';
    font-style: italic;
    font-weight: bold;
    src: url(../../public/fonts/SegoePro-BoldItalic.ttf);
  }
  @font-face {
    font-family: 'segoe-pro';
    font-weight: 600;
    src: url(../../public/fonts/SegoePro-Semibold.ttf);
  }
  @font-face {
    font-family: 'segoe-pro';
    font-style: italic;
    font-weight: 600;
    src: url(../../public/fonts/SegoePro-SemiboldItalic.ttf);
  }
}

@property --num-dealers {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

@property --num-brands {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

@property --num-products {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

@property --num-workers {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

@keyframes counter {
  from {
    --num-dealers: 0;
    --num-brands: 0;
    --num-products: 0;
    --num-workers: 0;
  }
  to {
    --num-dealers: 2000;
    --num-brands: 100;
    --num-products: 2500;
    --num-workers: 50;
  }
}

::-webkit-scrollbar-track {
  background-color: #00000000;
  width: 10px;
}

::-webkit-scrollbar {
  height: 0px;
  width: 4px;
  background-color: #fafafa00;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: #3a3a3a7a;
  border: 0px solid #55555500;
}